export const names = [
    "Arden",
    "Bellamy",
    "Briar",
    "Brighton",
    "Callaway",
    "Cove",
    "Cypress",
    "Ever",
    "Halston",
    "Hollis",
    "Honor",
    "Jupiter",
    "Kingsley",
    "Kit",
    "Landry",
    "Lexington",
    "Lux",
    "Merritt",
    "Ocean",
    "Revel",
    "Rory",
    "Salem",
    "Sol",
    "Timber",
    "Jessie",
    "Marion",
    "Jackie",
    "Alva",
    "Ollie",
    "Jodie",
    "Cleo",
    "Kerry",
    "Frankie",
    "Guadalupe",
    "Carey",
    "Tommie",
    "Angel",
    "Sammie",
    "Jamie",
    "Kris",
    "Robbie",
    "Tracy",
    "Merrill",
    "Noel",
    "Rene",
    "Johnnie",
    "Ariel",
    "Jan",
    "Casey",
    "Jackie",
    "Kerry",
    "Jodie",
    "Finley",
    "Skylar",
    "Justice",
    "Rene",
    "Darian",
    "Frankie",
    "Oakley",
    "Robbie",
    "Remy",
    "Milan",
    "Jaylin",
    "Devan",
    "Armani",
    "Charlie",
    "Stevie",
    "Channing",
    "Gerry",
    "Monroe",
    "Kirby",
    "Azariah",
    "Santana"
]
