

const FAQ = () => {

    // ideally will have Frequently Asked Questions
  return (
    <div>
      <div>FAQ</div>
    </div>  
    )
}

export default FAQ;
