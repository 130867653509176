

const ProductDetails = () => {
  return (

      // ideally will have further details about the SERVICE
      // should really be called ServiceDetails
    <div>
      <div>FAQ</div>
    </div>  
    )
}

export default ProductDetails;
