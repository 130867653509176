
const CompanyDetails = () => {

  // ideally will have further details about the company
  return (
    <div>
      <div>CompanyDetails</div>
    </div>
  )
}

export default CompanyDetails;
